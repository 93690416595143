<template>
    <custom-dialog :visible.sync="visible" :title="title" @close="colseDialog" @submit="submit" width="50%">
      <el-form :model="editForm" label-width="100px" :rules="rules" ref="editVersionForm">
      <el-row >
        <el-col :span="12">
          <el-form-item label="程序标识" prop="built">
            <el-input v-model="editForm.built" size="small" placeholder="请输入程序标识"></el-input>
<!--            <el-select-->
<!--                class="block-select"-->
<!--                size="small"-->
<!--                clearable-->
<!--                v-model="editForm.built"-->
<!--                placeholder="请选择程序标识">-->
<!--              <el-option-->
<!--                  key="item.id"-->
<!--                  label="item.name"-->
<!--                  value="item.id"-->
<!--              ></el-option>-->
<!--            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="版本类别" prop="type">
            <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.type"
                placeholder="请选择版本类别">
              <el-option
                  v-for="item in equipType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="固件版本" prop="ver">
            <el-input size="small" v-model="editForm.ver" placeholder="请输入固件版本"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="升级文件名" prop="sourcefile">
            <el-input size="small" v-model="editForm.sourcefile" placeholder="请输入升级文件名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="升级包" prop="url">
            <el-upload
                class="file-uploader"
                :action="`${uploadUrl}deviceVer/uploadVersion`"
                :data="{type:1}"
                :show-file-list="false"
                :on-success="uploadSuccess">
              <div class="upload-main">
                  <span class="upload-btn">
                    <i class="el-icon-upload"></i>上传升级包
                  </span>
                请上传升级包
              </div>
              <div class="file-url-main">
                {{editForm.url}}
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
<!--        <el-col :span="24">-->
<!--          <el-form-item label="升级说明" prop="compName">-->
<!--            <el-upload-->
<!--                class="file-uploader"-->
<!--                :action="`${uploadUrl}deviceVer/uploadVersion`"-->
<!--                :data="{type:1}"-->
<!--                :show-file-list="false"-->
<!--                :on-success="uploadSuccessJson">-->
<!--              <div class="upload-main">-->
<!--                  <span class="upload-btn">-->
<!--                    <i class="el-icon-upload"></i>上传升级说明-->
<!--                  </span>-->
<!--                请上传升级说明output.json-->
<!--              </div>-->
<!--              <div class="file-url-main">-->
<!--              </div>-->
<!--            </el-upload>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="24">
          <el-form-item label="备注" prop="dismNote">
            <el-input size="small"  type="textarea"
                      :rows="2" v-model="editForm.dismNote" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    </custom-dialog>
  
  </template>
  
  <script>
  import CustomDialog from "../../components/common/CustomDialog.vue";
  import config from "../../api/config";
  import { equipType } from "../../config/dataStatus";
import sysManage from'../../api/modules/sysManage'
  export default {
    name: "EditVersion",
    components: {CustomDialog},
    props: ['visible', 'title'],
    data() {
      return {
        editForm: {},
        rules: {
        built:[
          { required: true, message: '请输入程序标识', trigger: 'blur' },
        ],
        type:[
          { required: true, message: '请选择版本类别', trigger: 'change' },
        ],
        ver:[
          { required: true, message: '请输入固件版本', trigger: 'blur' },
        ],
        url:[
          { required: true, message: '请选择升级包', trigger: 'change' },
        ],
        sourcefile:[
          { required: true, message: '请输入升级文件名', trigger: 'blur' },
        ],
      },
        uploadUrl: config.uploadUrl,
        equipType:equipType
        // equipType: equipType
      }
    },
    mounted(){
    
      
    },
    methods: {
      colseDialog() {
        this.$emit('close')
      },
      submit() {
        this.$refs.editVersionForm.validate((valid)=>{
          if(valid){
            sysManage.addDeviceVer(this.editForm).then(res =>{
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$emit("done")
            })
          }
        })
      },
      uploadSuccess(val) {
        console.log(val,'val')
        this.editForm.url = val.data.url
        this.editForm.sourcefile = val.data.sourcefile
        // this.editForm.progPackage =val.message
        this.$forceUpdate()
      },
      uploadSuccessJson(val){
        console.log(val)
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>