<template>
    <div class="pageMain">
      <search-head :model="searchForm" @search="search" @reset="reset">
        <el-form :inline="true" :model="searchForm">
        <el-form-item label="版本类别">
          <el-select v-model="searchForm.progType" size="small" placeholder="版本类别">
            <el-option label="手机端" value="手机端"></el-option>
            <el-option label="pc端" value="pc端"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="固件版本">
          <el-input v-model="searchForm.firmwareVersion" size="small" placeholder="程序标识"></el-input>
        </el-form-item>
        <!-- <el-form-item label="升级项目">
          <el-select v-model="searchForm.region" size="small" placeholder="升级项目">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="程序标识">
          <el-input v-model="searchForm.built" size="small" placeholder="程序标识"></el-input>
        </el-form-item>
        <!-- <el-form-item label="开始日期">
              <el-date-picker
                v-model="searchForm.user"
                type="datetime"
                placeholder="选择开始日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker
                v-model="searchForm.user"
                type="datetime"
                placeholder="选择结束日期"
              >
              </el-date-picker>
            </el-form-item> -->
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="版本信息">
        <template slot="btns">
          <el-button type="success" size="small" @click="addFrom" icon="el-icon-plus" class="addBtn">新增</el-button>
        </template>
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="built" label="程序标识" align="center"></el-table-column>
      <el-table-column prop="type" label="版本类别" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">塔吊</span>
          <span v-if="scope.row.type == 1">升降机</span>
          <span v-if="scope.row.type == 2">龙门吊</span>
          <span v-if="scope.row.type == 3">架桥机</span>
          <span v-if="scope.row.type == 4">爬模</span>
          <span v-if="scope.row.type == 5">挂篮</span>
        </template>
      </el-table-column>
      <el-table-column prop="ver" label="版本信息" align="center"></el-table-column>
      <el-table-column prop="sourcefile" label="升级包" width="280" align="center"></el-table-column>
      <el-table-column prop="timestamps" label="发布时间" align="center"></el-table-column>
      <el-table-column prop="publisher" label="发布人" align="center"></el-table-column>
       
        <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
           
            <span
                @click="deleteItem(scope.row)"
                class="detail "
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column>
      </custom-tables>
      <versionReleaseEdit  :visible.sync="showDialog" @close="colseDialog" :title="title"   @done="doneDialog"></versionReleaseEdit>
     
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import versionReleaseEdit from '../../../components/systemManage/versionReleaseEdit.vue'
  import sysManage from '../../../api/modules/sysManage'
  import { equipType,deviceState } from "../../../config/dataStatus";

  export default {
    name: "index",
    components: {versionReleaseEdit, CustomTables, SearchHead},
    data() {
      return {
        tableData: [
          
        ],
       
        searchForm:{
          currPage: 1,
        pageSize:10
        },
        total:0,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
        
      },
      showDialog:false,
      equipTypeObj:equipType,
      title:''
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      getList(){
      sysManage.getVersionListByPage(this.searchForm).then(res=>{
        this.total = res.data.total
      this.tableData = res.data.list
      })
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
      addFrom(){
        this.showDialog = true
        this.title = '发布版本'
      },
      colseDialog(){
        this.showDialog = false
        this.getList();
      },
      doneDialog(){
      this.showDialog = false;
      this.getList();
    },
      submit(){
        this.showDialog = false
      },
      detailItem(){
        //详情
    },
    deleteItem(item) {
      console.log(item, 'item');
      this.$confirm(`此操作将永久删除${item.built}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        sysManage.delVersion(item.progId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      })
    },
    handleSelectionChange(val){
        console.log(val);
        
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  
    }
  }
  </script>
  
  <style scoped lang="less">
  .detail{
    color: #008f4d;
}
.delete{
    margin-left: 10px;
}
.addBtn{
    background-color:#008f4d;
}
  </style>